export enum AuditTypes {
	Bookings = 1,
	Timesheets = 2,
	Clients = 3,
	Candidates = 4,
	Placeholders = 5,
	Marketing = 6,
	Student = 7,
}

export enum ChartTypes {
	All = 1,
	MovingAverage = 2,
	AverageOverTime = 3,
}

export enum GroupBy {
	Daily = 1,
	Weekly = 2,
	Monthly = 3,
}
