export enum ReportType {
	BookingsCreated = 1,
	BookingsDayChanges = 2,
	BookingsCostsChanged = 3,
	BookingsCancelled = 4,
	CandidatesCreated = 5,
	CandidatesToApproved = 6,
	CandidatesToPending = 7,
	CandidatesToArchived = 8,
	CandidatesAnonymised = 9,
	CandidatesOnboardingRequested = 10,
	CandidatesOnboardingCompleted = 11,
	CandidatesBankRequested = 12,
	CandidatesDocumentRequests = 13,
	CandidateReferenceRequests = 14,
	ClientCreate = 15,
	ClientContactsUpdated = 16,
	TimesheetsCreated = 17,
	PlacementCreate = 18,
	PlacementComplete = 19,
	PlacementCancelled = 20,
	CampaignsSent = 21,
	ClientUtilization = 22,
	CandidatesInBookings = 23,
	StandardBookingsCreated = 24,
	IATBookingsCreated = 25,
	CandidatesDocumentsExpiring = 26,
	BookingProfitLostByType = 27,
	TotalBookings = 28,
	TotalIATs = 29,
	BookingsDayAdded = 30,
	BookingsDayRemoved = 31,
	CandidateBdCalls = 32,
	CandidateCalls = 33,
	CandidateMeetings = 34,
	ClientBdCalls = 35,
	ClientCalls = 36,
	ClientMeetings = 37,
	BookingsDayTotal = 38,
	BookingsDayAddedCreated = 39,
	BookingsDayRemovedCancelled = 40,
	CandidatesOthersExpiring = 41,
	BookingsAwrReminders = 42,
}

export enum IndividualReportType {
	BookingsStandardBookingsCreatedLine = 1,
	BookingsIATBookingsCreatedLine = 2,
	BookingsDaysOutLine = 3,
	BookingsBookingDayChangesLine = 4,
	BookingsBookingCostsChangedLine = 5,
	BookingsBookingsCancelledLine = 6,
	BookingsProfitLossBar = 7,
	BookingsProfitLossByTypeBar = 8,
	BookingsTotalBookingsStat = 9,
	BookingsTotalIaTsStat = 10,
	BookingsBookingDaysAddedStat = 11,
	BookingsBookingDaysRemovedStat = 12,
	BookingsBookingDaysChangeStat = 13,
	BookingsProfitLossByTypeStat = 14,
	BookingsProfitLossDaysRemovedStat = 15,
	BookingsProfitStat = 16,
	BookingsCommissionStat = 17,

	CandidatesCandidateEntry = 18,
	CandidatesCandidatesToApproved = 19,
	CandidatesCandidatesToPending = 20,
	CandidatesCandidatesToArchived = 21,
	CandidatesCandidatesAnonymised = 22,
	CandidatesCandidatesOnboardingRequested = 23,
	CandidatesCandidatesOnboardingCompleted = 24,
	CandidatesCandidatesBankRequested = 25,
	CandidatesCandidatesDocumentRequests = 26,
	CandidatesCandidateReferenceRequests = 27,
	CandidatesBDCalls = 28,
	CandidatesCandidateCalls = 29,
	CandidatesCandidateMeetings = 30,
	CandidatesDocumentsExpiring = 31,
	CandidatesCandidatesInBookings = 32,
	CandidatesCandidateUtilisation = 33,
	CandidatesTotalCandidatePay = 34,

	TimesheetsTimesheetsCreated = 35,
	TimesheetsClientApproved = 36,
	TimesheetsCandidateApproved = 37,
	TimesheetsClientQueried = 38,
	TimesheetsCandidateQueried = 39,

	VacanciesVacancyCreate = 40,
	VacanciesVacancyComplete = 41,
	VacanciesVacancyCancelled = 42,

	ClientsClientCreate = 43,
	ClientsClientContactsUpdated = 44,
	ClientsBDCalls = 45,
	ClientsClientCalls = 46,
	ClientsClientMeetings = 47,
	ClientsClientSpend = 48,
	ClientsClientProfit = 49,
	ClientsClientUtilisation = 50,
	ClientsTotalClientSpend = 51,
	ClientsTotalClientProfit = 52,

	MarketingCampaignsSent = 53,
	MarketingTotalRecipients = 54,
	MarketingSuccessfullyDelivered = 55,
	MarketingOpens = 56,
	MarketingClicks = 57,
	MarketingBounced = 58,
	MarketingDeferred = 59,

	CandidatesOthersExpiring = 60,
	BookingsAwrReminders = 61,
}
