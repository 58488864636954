import styled from 'styled-components'
import { RelSize } from '../mixins'
import theme from '../theme'

export const LoadingWrapper = styled.div`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    display: flex;
	flex-direction: column;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999999 !important;
`;

export const Loader = styled.div`
    font-size: 10px;
	text-indent: -9999em;
	width: ${RelSize(50, 16)};
	height: ${RelSize(50, 16)};
	border-radius: 50%;
	background: ${props => props.alt ? theme.quarterinaryGreen : theme.primaryGreen};
	background: -moz-linear-gradient(left, ${props => props.alt ? theme.quarterinaryGreen : theme.primaryGreen} 10%, rgba(255, 255, 255, 0) 42%);
	background: -webkit-linear-gradient(left, ${props => props.alt ? theme.quarterinaryGreen : theme.primaryGreen} 10%, rgba(255, 255, 255, 0) 42%);
	background: -o-linear-gradient(left, ${props => props.alt ? theme.quarterinaryGreen : theme.primaryGreen} 10%, rgba(255, 255, 255, 0) 42%);
	background: -ms-linear-gradient(left, ${props => props.alt ? theme.quarterinaryGreen : theme.primaryGreen} 10%, rgba(255, 255, 255, 0) 42%);
	background: linear-gradient(to right, ${props => props.alt ? theme.quarterinaryGreen : theme.primaryGreen} 10%, rgba(255, 255, 255, 0) 42%);
	position: relative;
	-webkit-animation: loading 1.4s infinite linear;
	animation: loading 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	&::before {
		width: 50%;
		height: 50%;
		background: ${props => props.alt ? theme.quarterinaryGreen : theme.primaryGreen};
		border-radius: 100% 0 0 0;
		position: absolute;
		top: 0;
		left: 0;
		content: '';
	}
	&::after {
		background: ${theme.white};
		width: 75%;
		height: 75%;
		border-radius: 50%;
		content: '';
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
`;

export const LoadingTextWrapper = styled.div`
	background-color: ${theme.secondaryGreen};
	border-radius: ${RelSize(10, 14)};
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
	color: ${theme.white};
	font-size: ${RelSize(14, 16)};
	margin-top: ${RelSize(20, 14)};
	max-width: ${RelSize(250, 14)};
	padding: ${RelSize(20, 14)};
	text-align: center;
	p {
		margin: 0;
	}
`;

export const VerificationLoaderWrapper = styled.div`
	align-items: center;
	background-color: ${theme.primaryGreen};
	border-radius: ${RelSize(5, 16)};
	cursor: default;
	display: flex;
	height: ${RelSize(22, 16)};
	justify-content: center;
	pointer-events: none;
	> div {
		width: ${RelSize(5, 16)};
		height: ${RelSize(5, 16)};
		margin: 0 ${RelSize(5, 16)};
		background-color: ${theme.white};
		border-radius: 50%;
		animation: jump 0.6s infinite;
		&:nth-child(1) {
			animation-delay: 0s;
		}
		&:nth-child(2) {
			animation-delay: 0.2s;
		}
		&:nth-child(3) {
			animation-delay: 0.4s;
		}
	}
`;

export const VerificationLoadingAnimation = styled.div`
	height: ${RelSize(200, 16)};
	padding-top: 58.5772508336421%;
	position: relative;
	width: 100%;
	.identity-document {
		bottom: 0;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	svg {
		border: 0;
		display: block;
		height: 100%;
		margin: 0;
		width: 100%;
	}
	
	.document-header {
		font-weight: bold;
		border-bottom: 1px solid #000;
		padding-bottom: ${RelSize(5, 16)};
		margin-bottom: ${RelSize(10, 16)};
	}
	
	.document-body p {
		margin: ${RelSize(5, 16)} 0;
	}
	
	.magnifying-glass {
		position: absolute;
		width: ${RelSize(50, 16)};
		height: ${RelSize(50, 16)};
		border: ${RelSize(5, 16)} solid #000;
		border-radius: 50%;
		right: ${RelSize(50, 16)};
		top: ${RelSize(50, 16)};
		background-color: rgba(255, 255, 255, 0.7);
		animation: moveMagnifier 5s infinite alternate ease-in-out;
	}

	@-webkit-keyframes moveMagnifier {
		0% {
			right: ${RelSize(50, 16)};
			top: ${RelSize(50, 16)};
		}
		100% {
			right: ${RelSize(200, 16)};
			top: ${RelSize(150, 16)};
		}
	}
	
	@keyframes moveMagnifier {
		0% {
			right: ${RelSize(50, 16)};
			top: ${RelSize(50, 16)};
		}
		100% {
			right: ${RelSize(200, 16)};
			top: ${RelSize(150, 16)};
		}
	}
`;