import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toggleLoading } from "../../state/creators";
import { DOCS_URL } from "../../config/endpoints";
import { useAuth } from "../../state/context/auth.store";
import { useModalsStore } from "../../state/context/modal.store";
import { CLOSE_MODAL } from "../../state/context/modals.reducers";
import { ModalWrapper, ModalContent, ModalHelpContent, ModalClose, ModalHeader, ModalFooter } from "../../styles/modals";
import { AltButtonAnchor, ButtonList, StandardButtonAnchor, StandardCancelAnchor } from "../../styles/type/buttons";
import { H2, H3 } from "../../styles/type/headings";
import { PermissionsValidate } from "../../functions/helpers";
import { Permissions } from "../../_enums/permissions.enum";
import { HideOnboard } from "../../functions/http-functions/user";

interface Props {
	toggleLoading: (loading: boolean) => void;
}

const OnboardingModal = ({ toggleLoading }: Props) => {
	const { auth, setOnboarding } = useAuth();
	const { dispatch } = useModalsStore();

	const [activeSection, setActiveSection] = useState<number>(0);
	const [sections, setSections] = useState<string[]>([]);

	useEffect(() => {
		if (auth?.token) setOnboardingModules();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.token]);

	const setOnboardingModules = () => {
		let activeSections = [];
		if (PermissionsValidate(auth.token, [Permissions.CanManageClients])) activeSections.push("Clients");
		if (PermissionsValidate(auth.token, [Permissions.CanManageCandidates])) activeSections.push("Candidates");
		if (PermissionsValidate(auth.token, [Permissions.CanManageBookings])) activeSections.push("Bookings");
		if (PermissionsValidate(auth.token, [Permissions.CanManageBookings])) activeSections.push("Vacancy Manager");
		if (PermissionsValidate(auth.token, [Permissions.CanManageTimesheets])) activeSections.push("Timesheets");
		if (PermissionsValidate(auth.token, [Permissions.CanManageTutorBookings])) activeSections.push("Tuition");
		if (PermissionsValidate(auth.token, [Permissions.CanManageMarketing])) activeSections.push("Marketing");
		if (PermissionsValidate(auth.token, [Permissions.CanManageInvoices])) activeSections.push("Invoices");
		if (PermissionsValidate(auth.token, [Permissions.CanManagePayments])) activeSections.push("Payment Runs");
		if (PermissionsValidate(auth.token, [Permissions.CanViewAnalytics])) activeSections.push("Reporting");

		setSections(activeSections);
	};

	const closeOnboarding = async () => {
		try {
			toggleLoading(true);
			await HideOnboard(auth.token, auth?.user?._id);
		} catch (err: unknown) {
		} finally {
			toggleLoading(false);
			setOnboarding(false);
			dispatch({
				type: CLOSE_MODAL,
			});
		}
	};

	return (
		<>
			<ModalWrapper>
				<ModalHeader>
					<H2>Edun. Quick Start. 🎉</H2>
					<ModalClose onClick={closeOnboarding}>Hide</ModalClose>
				</ModalHeader>
				<ModalContent>
					<ModalHelpContent>
						{!activeSection ? (
							<>
								<p>Navigate through our quick start guide to familiarise yourself with the modules within Edun.</p>
								<p>
									Dive into our documentation to explore features from booking system, electronic timesheeting, to client management, and more. Enhance your workflow, engage with
									candidates and clients effectively, and ensure a smoother recruitment process.
								</p>
								<p>
									Find out more using our help and support site.{" "}
									<a href={DOCS_URL} target="_blank" rel="noreferrer">
										Click here to view
									</a>{" "}
									(external site) , or walk through our quick introductions to each module by clicking &quot;Lets Begin&quot;.
								</p>
							</>
						) : (
							<>
								{sections[activeSection - 1] === "Clients" ? (
									<>
										<H3>Clients</H3>
										<p>What education institutions do you work with. These can be managed from the client module.</p>
										<p>
											All bookings require a client, and external communications for clients such as timesheet requests, booking confirmations etc can be shared with client
											contacts that get added.
										</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Candidates" ? (
									<>
										<H3>Candidates</H3>
										<p>Who is filling your vacancies. Your candidates (teachers, tutors, etc) can be managed from the candidate module.</p>
										<p>Filter candidates into various workflow statuses, manage their onboarding and registration into your business.</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Bookings" ? (
									<>
										<H3>Bookings</H3>
										<p>Manage candidate bookings, view basic personal and business financial statistics related to those bookings.</p>
										<p>Ability to manage standard placements, candidate trial and interviews, tuition and permanent placements.</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Vacancy Manager" ? (
									<>
										<H3>Vacancy Manager</H3>
										<p>Have vacancies that need filling? Vacancy manager is where this happens.</p>
										<p>Find candidates suitable for placement vacancies and send out batch placement requests to potentials, converting into a successful booking.</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Timesheets" ? (
									<>
										<H3>Timesheets</H3>
										<p>Create and manage booking timesheets, get both candidate and client approval and resolve potential queries through timesheet correspondence within Edun.</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Tuition" ? (
									<>
										<H3>Tuition</H3>
										<p>Deal with tuition bookings? Manage your students and tuition reports from the Tuition module.</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Marketing" ? (
									<>
										<H3>Marketing</H3>
										<p>Create marketing campaigns using pre-defined templates created from within Edun.</p>
										<p>Search for your client or candidate contacts and build up your recipient list.</p>
										<p>Dynamically include useful information to personalise your campaigns for each recipient.</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Invoices" ? (
									<>
										<H3>Invoices</H3>
										<p>We currently offer Xero integration for managing invoices directly from within Edun, all managed from the invoice module.</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Payment Runs" ? (
									<>
										<H3>Payment Run</H3>
										<p>Create payment runs against approved timesheets, exporting bespoke information for payroll purposes.</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Reporting" ? (
									<>
										<H3>Reporting</H3>
										<p>
											Reporting in Edun serves as a pivotal tool for consultants, primarily admins, to gain insight into various user activities, progression, and output metrics.
										</p>
										<p>
											With an emphasis on transparency and actionable insights, this module provides a comprehensive view ranging from the nuances of candidate onboarding to
											broader aspects like gross profit.
										</p>
									</>
								) : null}
								{sections[activeSection - 1] === "Knowledge Base" ? (
									<>
										<H3>Knowledge Base</H3>
										<p>Your comprehensive guide to mastering Edun - the tailored CRM solution designed to empower education recruiters.</p>
										<p>
											Dive into our documentation to explore features from booking system, electronic timesheeting, to client management, and more. Enhance your workflow, engage
											with candidates and clients effectively, and ensure a smoother recruitment process.
										</p>
									</>
								) : null}
							</>
						)}
					</ModalHelpContent>
				</ModalContent>
				<ModalFooter>
					<ButtonList justify="space-between">
						<StandardCancelAnchor onClick={closeOnboarding}>Hide</StandardCancelAnchor>
						<div>
							<ButtonList>
								{sections.length && activeSection ? <AltButtonAnchor onClick={() => setActiveSection(activeSection - 1)}>Go Back</AltButtonAnchor> : null}
								{sections.length ? (
									<StandardButtonAnchor onClick={() => setActiveSection(sections[activeSection] ? activeSection + 1 : 0)}>
										{!activeSection ? `Lets Begin with ${sections[0]}` : sections[activeSection] ? sections[activeSection] : "Start Again"}
									</StandardButtonAnchor>
								) : null}
							</ButtonList>
						</div>
					</ButtonList>
				</ModalFooter>
			</ModalWrapper>
		</>
	);
};

const mapDispatchToProps = {
	toggleLoading,
};

export default connect(null, mapDispatchToProps)(OnboardingModal);
