import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toggleLoading, setAlert } from "../../state/creators";
import { ApiResultModel } from "../../_models/api.result.model";
import { GetClientsFilter, GetClientsIds } from "../../functions/http-functions/clients";
import { UpdateFilter } from "../../functions/helpers";
import { ModalWrapper, ModalContent, ModalClose, ModalHeader } from "../../styles/modals";
import { H2 } from "../../styles/type/headings";
import { TableWrapper, THeadLabel, Tr, FilterPlaceholder } from "../../styles/tables/table";
import Row from "../../styles/layout/row";
import Col from "../../styles/layout/col";
import { ButtonList, StandardButtonAnchor, StandardCancelAnchor } from "../../styles/type/buttons";
import { DefaultPaginationModel } from "../../_models/data.defaultPagination.model";
import { ListingModel } from "../../_models/data.listingResult.model";
import { FilterModel } from "../../_models/data.filter.model";
import Table from "../table";
import { IHttpResponse } from "../../functions/http-functions/http";
import { useAuth } from "../../state/context/auth.store";
import { ClientInterface } from "../../_types/client";

const LinkClientsModal = ({ user, clients = [], linkClients, closeModal, toggleLoading, setAlert }) => {
	const { auth } = useAuth();
	const [selectedClients, updateSelectedClients] = useState(clients);
	const [allClients, setAllClients] = useState([]);

	const [{ resultItems, pageNo, pageSize, paginationItems, totalRecords, resultItemsAll, filters, order }, setState] = useState({
		...new DefaultPaginationModel(),
		...{ totalRecords: 0, resultItemsAll: [], filters: [], order: null },
	});

	useEffect(() => {
		setState((prevState) => ({
			...prevState,
			...new ListingModel({
				pageNo,
				pageSize,
				resultItems: [],
				resultItemsAll,
				totalRecords: 0,
			}),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		filter({ pageNo: 1, pageSize: 10 }, filters, order);
		getAllClients();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updatePage = async (type, config, tableFilter, orderFilter) => {
		const pageNoValue = type === "reset" || type === "start" ? 1 : config?.pageNo || pageNo;
		const pageSizeValue = config?.pageSize || pageSize;

		const FilterValues = await UpdateFilter(type, {
			pageNo: pageNoValue,
			pageSize: pageSizeValue,
		});

		if (tableFilter) {
			setState((prevState) => ({
				...prevState,
				...{
					filters: tableFilter,
				},
			}));
		}

		if (orderFilter) {
			setState((prevState) => ({
				...prevState,
				...{
					order: orderFilter,
				},
			}));
		}

		filter(FilterValues, tableFilter || filters, orderFilter || order);
	};

	const filter = (FilterValues, TableFilter, OrderFilter) => {
		toggleLoading(true);
		GetClientsFilter(auth.token, new FilterModel(FilterValues, TableFilter, OrderFilter))
			.then((res) => {
				setState((prevState) => ({
					...prevState,
					...FilterValues,
					...new ListingModel(
						{
							resultItems: res.data.map((item) => ({
								...item,
								...{
									"address.postcode": item.address ? item.address.postcode : null,
									"address.county": item.address ? item.address.county : null,
									selected: selectedClients.find((client) => item._id === client),
								},
							})),
							totalRecords: res.total,
						},
						FilterValues
					),
				}));
			})
			.catch((err: unknown) => {
				const error = err as IHttpResponse;
				const ErrorResult = new ApiResultModel(error);
				setAlert({ type: "error", message: ErrorResult.message });
			})
			.then(() => toggleLoading(false));
	};

	const getAllClients = () => {
		toggleLoading(true);
		GetClientsIds(auth.token)
			.then((res) => {
				setAllClients(res.data);
			})
			.catch((err: unknown) => {
				const error = err as IHttpResponse;
				const ErrorResult = new ApiResultModel(error);
				setAlert({ type: "error", message: ErrorResult.message });
			})
			.then(() => toggleLoading(false));
	};

	const selectClient = (client: ClientInterface) => {
		const updatedClients = selectedClients.find((prevClient) => prevClient === client._id)
			? selectedClients.filter((prevClient) => prevClient !== client._id)
			: [...selectedClients, ...[client._id]];

		updateSelectedClients(updatedClients);

		setState((prevState) => ({
			...prevState,
			...{
				resultItems: resultItems.map((item) => (item._id === client._id ? { ...item, ...{ selected: updatedClients.find((prevClient) => prevClient === item._id) ? true : false } } : item)),
			},
		}));
	};

	const removeClient = (clientID) => {
		updateSelectedClients(selectedClients.filter((client) => client !== clientID));

		setState((prevState) => ({
			...prevState,
			...{
				resultItems: resultItems.map((item) => (item._id === clientID ? { ...item, ...{ selected: false } } : item)),
			},
		}));
	};

	const selectAllVisible = () => {
		updateSelectedClients([...selectedClients, ...resultItems.map((item) => item._id)]);

		setState((prevState) => ({
			...prevState,
			...{
				resultItems: resultItems.map((item) => ({
					...item,
					...{ selected: true },
				})),
			},
		}));
	};

	const removeAllVisible = () => {
		updateSelectedClients(selectedClients.filter((selectedClient) => !resultItems.find((client) => client._id === selectedClient)));

		setState((prevState) => ({
			...prevState,
			...{
				resultItems: resultItems.map((item) => ({
					...item,
					...{ selected: false },
				})),
			},
		}));
	};

	const submitLinkClients = () => {
		linkClients(selectedClients);
	};

	const TableProperties: { navigate?: any; columns?: any } = {
		columns: [
			{
				Name: "Select",
				Value: "selected",
				Type: "Select",
			},
			{
				Name: "Name",
				Value: "name",
				Type: "String",
				Filter: "Search",
				Order: "String",
			},
			{
				Name: "Telephone",
				Value: "telephone",
				Type: "String",
				Filter: "Search",
				Order: "String",
			},
			{
				Name: "Email",
				Value: "email",
				Type: "String",
				Filter: "Search",
				Order: "String",
			},
			{
				Name: "Postcode",
				Value: "address.postcode",
				Type: "String",
				Filter: "Search",
				Order: "String",
				OrderValue: "address.postcode",
			},
			{
				Name: "County",
				Value: "address.county",
				Type: "String",
				Filter: "Search",
				Order: "String",
				OrderValue: "address.county",
			},
			{
				Name: "LEA",
				Value: "lea",
				Type: "String",
				Filter: "Search",
				Order: "String",
			},
			{
				Name: "Type",
				Value: "type",
				Type: "String",
				Filter: "Search",
				Order: "String",
			},
			{
				Name: "MAT",
				Value: "multiAcademyTrust",
				Type: "String",
				Filter: "Search",
				Order: "String",
			},
		],
	};

	return (
		<>
			<ModalWrapper>
				<>
					<ModalHeader>
						<H2>Select clients to link to {user}</H2>
						<ModalClose onClick={closeModal}>close</ModalClose>
					</ModalHeader>
					<ModalContent>
						<Row>
							<Col span={9}>
								<ButtonList pushBottom>
									<StandardButtonAnchor small onClick={selectAllVisible}>
										Add All Returned Clients
									</StandardButtonAnchor>
									{selectedClients.length ? (
										<StandardCancelAnchor small onClick={removeAllVisible}>
											Remove all Returned Clients
										</StandardCancelAnchor>
									) : null}
								</ButtonList>
								<div>
									<Table
										resultItems={resultItems}
										tableProperties={TableProperties}
										pageNo={pageNo}
										pageSize={pageSize}
										UpdatePage={updatePage}
										paginationItems={paginationItems}
										totalRecords={totalRecords}
										scaleTable={true}
										returnItem={selectClient}
										fullPagination={true}
										showTotal={true}
									/>
								</div>
							</Col>
							<Col span={3}>
								<ButtonList pushBottom justify="flex-end">
									<StandardButtonAnchor small onClick={submitLinkClients}>
										Link Clients to {user}
									</StandardButtonAnchor>
								</ButtonList>
								<TableWrapper full sub={true}>
									<table>
										<thead>
											<tr>
												<th>
													<THeadLabel sub={true}>
														<span>Linked Clients</span>
													</THeadLabel>
													<FilterPlaceholder />
												</th>
												<th>
													<THeadLabel sub={true}>
														<span>Remove</span>
													</THeadLabel>
													<FilterPlaceholder />
												</th>
											</tr>
										</thead>
										<tbody>
											{!selectedClients.length ? (
												<Tr>
													<td colSpan={100}>
														<p
															style={{
																margin: 0,
															}}
														>
															No clients linked.
														</p>
													</td>
												</Tr>
											) : null}
											{selectedClients.map((item, index) => (
												<Tr key={index}>
													<td>
														<span>{allClients.find((client) => client._id === item)?.name}</span>
													</td>
													<td>
														<span>
															<a onClick={() => removeClient(item)}>Remove</a>
														</span>
													</td>
												</Tr>
											))}
										</tbody>
									</table>
								</TableWrapper>
							</Col>
						</Row>
					</ModalContent>
				</>
			</ModalWrapper>
		</>
	);
};

const mapDispatchToProps = {
	toggleLoading,
	setAlert,
};

export default connect(null, mapDispatchToProps)(LinkClientsModal);
