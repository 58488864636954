export enum RemarkTypes {
	candidate = 1,
	timesheet = 2,
	client = 3,
	booking = 4,
	student = 5,
	branch = 6,
}

export enum RemarksTags {
	BDCall = 1,
	CandidateCall = 2,
	ClientCall = 3,
	Meeting = 4,
	Compliance = 5,
	CandidateEmail = 6,
	CandidateSms = 7,
}
