export enum OccupationTypes {
	Teacher = "Teacher",
	CoverSupervisor = "Cover Supervisor",
	TeachingAssistant = "Teaching Assistant",
	SchoolSupportStaff = "School Support Staff",
	PrimaryTeacher = "Primary Teacher",
	SecondaryTeacher = "Secondary Teacher",
	HeadTeacher = "Head Teacher",
	SeniorLeader = "Senior Leader",
	Tutor = "Tutor",
	Mentor = "Mentor",
	LearningSupportAssistant = "Learning Support Assistant",
	FinanceOfficer = "Finance Officer",
	HRAdministrator = "HR Administrator",
	HRManager = "HR Manager",
	OfficeManager = "Office Manager",
	Administrator = "Administrator",
	PersonalAssistant = "Personal Assistant",
	PastoralManager = "Pastoral Manager",
	Receptionist = "Receptionist",
	Instructor = "Instructor",
	Caretaker = "Caretaker",
	NurseryAssistant = "Nursery Assistant",
	NurseryNurse = "Nursery Nurse",
	DeputyNurseryManager = "Deputy Nursery Manager",
	LunchtimeSupervisor = "Lunchtime Supervisor",
	ClassroomGraduate = "Classroom Graduate",
	PrimaryCoverSupervisor = "Primary Cover Supervisor",
	HLTA = "HLTA",
	NurtureTeacher = "Nurture Teacher",
	NurtureMentor = "Nurture Mentor",
	SENDCo = "SENDCo",
	RegisteredManager = "Registered Manager",
	RegionalManager = "Regional Manager",
	EducationDirector = "Education Director",
	ResidentialDirector = "Residential Director",
	DeputyManager = "Deputy Manager",
	ResidentialSupportWorker = "Residential Support Worker",
	SportsCoach = "Sports Coach",
	ExecutiveHeadteacher = "Executive Headteacher",
	GeneralPractitioner = "General Practitioner",
	AdvanceNursePractitioner = "Advance Nurse Practitioner (ANP)",
	EmergencyNursePractitioner = "Emergency Nurse Practitioner (ENP)",
	AdvancedClinicalPractitioner = "Advanced Clinical Practitioner (ACP)",
	EmergencyClinicalPractitioner = "Emergency Clinical Practitioner (ECP)",
	ConsultantPsychiatrist = "Consultant Psychiatrist",
	PsychiatristSPR = "Psychiatrist – SPR",
}
