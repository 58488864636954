import React, { useState, useContext, createContext } from "react";

export interface IFormQuestion extends Document {
	customer: string;
	type: FormQuestionModuleType;
	question: FormQuestionType;
}

export enum FormQuestionModuleType {
	candidate = 1,
}

export enum FormQuestionType {
	studentLoan = 1,
	healthDeclaration = 2,
	safeQuardingQuestionaire = 3,
	nonUk = 4,
	socialMediaChecks = 5,
	noReferenceConsent = 6,
	referenceInternalApproval = 7,
	healthAndDisability = 8,
	candidateBorough = 9,
	healthDeclarationSignature = 10,
	referenceImmediateConsent = 11,
	bookingConfirmationNationalInsurance = 12,
	dbsBarredCheckUds = 13,
	educationalDisciplinaryProceedings = 14,
	keepingChildrenSafeExtendedCaution = 15,
	clientSpecialism = 16,
	referenceNote = 17,
	clientInternalIdSearch = 18,
	recordBoxNotes = 19,
	bookingConfirmationReferenceReceived = 20,
	healthDeclarationConcise = 21,
	keepingChildrenSafeKCSIE = 22,
	KeepingChildrenSafeProhibited = 23,
	candidateInterviewQuestions = 24,
	employmentHistoryExtended = 25,
	homeOfficeShareCode = 26,
	bookingConfirmationEwcConfirmation = 27,
	onboardingBankRequired = 28,
	onboardingDocumentsRequired = 29,
	referencePreventGenericEmail = 30,
	onboardingEmploymentHistoryRequired = 31,
	setDefaultPayRateToWeekly = 32,
	candidateSasOptIn = 33,
	overseasDeclarationExtended = 34,
	educationReferenceExtended = 35,
	invoiceTrackingCategories = 36,
	dunbarOnboardingRequirements = 37,
	reference24months = 38,
	onlineCheckConsent = 39,
	candidateTitle = 40,
	candidatePronoun = 41,
	candidateUmbrellaPaye = 42,
	holdenReferenceCustomMessage = 43,
	onboardingDocumentsNoCvRequired = 44,
	longtermTeacherHealthDeclaration = 45,
	employmentHistoryFull = 46,
	candidateExportFirstBooking = 47,
	onboardingTeacherPayScaleInput = 48,
	candidateP45 = 49,
	showOnlyHourlyRate = 50,
	additionalDbsComments = 51,
	defaultSendBookingConfirmationToYes = 52,
	moveCandidateSourceToTop = 53,
	candidateAssigneeFirst = 54,
	onboardingDocumentsPassportOnly = 55,
	healthSafetyCheckBooking = 56,
	poNumberBooking = 57,
	onboardingMedicalIntro = 58,
	medicalHealthDeclaration = 59,
	extendedOnboardingAbroadCheck = 60,
	hideSpecificDocumentsOnboarding = 61,
	candidateContractRoleType = 62,
	candidateRTWVerification = 63,
	onboardingCandidateInterview = 68,
	onboardingOverseas = 69,
	onboardingNumberRequired = 70,
	onboardingMedicalRequirements = 71,
	onboardingAlternativeReferenceDisclaimer = 72,
	onboardingVidaDeclaration = 73,
	onboardingTeacherOccupations = 74,
	onboardingHideProbation = 75,
	bookingConfirmationSafeguarding = 76,
	onboardingReferenceEqualOppotunities = 77,
	onboardingOverseasOffence = 78,
	overseasDeclarationExtendedReason = 79,
	bookingSplitDaysOut = 80,
	rightToWorkSubDocumentItems = 81,
	bookingSplitDaysOutKeepProfit = 82,
	showMedicalReferenceQuestions = 90,
	showAckButton = 100,
	showDocumentApproval = 110,
	candidateSourceRequired = 120,
	candidateEmploymentStartDate = 121,
	bookingTypeStandardOptionsOnly = 130,
	complianceTrn = 140,
	complianceSafeguarding = 141,
	compliancePreventTraining = 142,
	complianceBirthCertificate = 143,
	complianceNameChange = 144,
	complianceCyberSecurity = 145,
	complianceDbsDocsOnly = 146,
	additionalInterviewQuestions = 150,
	additionalSafeguardingInterviewQuestions = 151,
	hideInterviewQuestion = 152,
	interviewVideo = 153,
	hideInterivewPersonal = 154,
	hideInterviewSubjects = 155,
	hideInterviewQuestionsHolden = 156,
	showInterviewQuestionsHolden = 157,
	showStudentNameColumn = 160,
	showTrnNumberNotes = 161,
	bookingTutorVisibility = 200,
	candidateDefaultFuture = 210,
	dbsDeclarationRead = 220,
	tutitionHoursTaught = 230,
	tuitionNoHoursRemaining = 231,
	alternativeCharacterReferenceProForma = 240,
	vidaReferenceQuestions = 250,
	bookingTableAsc = 300,
	onboardingReferenceAllWorkHistory = 310,
	bookingConfirmationReferenceReceivedTotal = 320,
	bookingConfirmationHolidayPercentage = 321,
	vidaReferenceCustomMessage = 322,
	interviewTypesBasic = 323,
	simpleClientBookingConfirmation = 324,
	referenceDatesAsDates = 325,
	showDaytoDayBooking = 326,
	candidatesBookedTab = 330,
	clientArea = 340,
	lockKCSIE = 350,
	onboardingProfilePhotoRequired = 360,
	ComplianceUser = 370,
	AvailabilityLastChecked = 371,
	SubsidiarySubject = 372,
	SubjectToNotice = 373,
	EmploymentPreference = 374,
	Governance = 375,
	MATHQ = 376,
	OfstedRating = 377,
	DateOfstedReportChecked = 378,
	Boarding = 379,
	LicenceToIssueSponsorship = 380,
	SchoolMATInformation = 381,
	AgencySpendTotal = 382,
	DesiredLocationMultiple = 383,
	identityGroup1Rename = 390,
	showCVCompliance = 391,
	bookingConfirmationChildcareDisqualification = 400,
	bookingConfirmationHealthDeclaration = 401,
	compliance2Pof = 410,
	bookingExportCreatedBy = 420,
	bookingExportCandidateAssignee = 421,
}

export interface GlobalFormQuestionsState {
	formQuestions: IFormQuestion[];
	setFormQuestions: (formQuestions: IFormQuestion[]) => void;
}

export const initialFormQuestionsState: GlobalFormQuestionsState = {
	formQuestions: null,
	setFormQuestions: () => {},
};

const FormQuestionsStoreContext = createContext<GlobalFormQuestionsState>(initialFormQuestionsState);

type Props = {
	children: React.ReactChild;
};

export const FormQuestionsStoreProvider = ({ children }: Props) => {
	const [formQuestions, setFormQuestions] = useState<IFormQuestion[]>(null);

	return (
		<FormQuestionsStoreContext.Provider
			value={{
				formQuestions,
				setFormQuestions,
			}}
		>
			{children}
		</FormQuestionsStoreContext.Provider>
	);
};

export const useFormQuestions = () => useContext(FormQuestionsStoreContext);
