import { DOCS_URL } from "../../config/endpoints";
import { useModalsStore } from "../../state/context/modal.store";
import { CLOSE_MODAL } from "../../state/context/modals.reducers";
import { ModalWrapper, ModalContent, ModalHelpContent, ModalClose, ModalHeader, ModalFooter } from "../../styles/modals";
import { H2 } from "../../styles/type/headings";
import OnboardingModal from "./onboardingModal";

type Props = {
	title: string;
	content: JSX.Element | JSX.Element[];
	external?: boolean;
};

const HelpModal = ({ title, content }: Props) => {
	const { dispatch, modal } = useModalsStore();

	return (
		<>
			<ModalWrapper>
				<ModalHeader>
					<H2>{title}</H2>
					<ModalClose
						onClick={() => {
							dispatch({
								type: CLOSE_MODAL,
							});
						}}
					>
						close
					</ModalClose>
				</ModalHeader>
				<ModalContent>
					<ModalHelpContent>{content}</ModalHelpContent>
				</ModalContent>
				{!modal?.external ? (
					<ModalFooter>
						<ModalHelpContent>
							<p>
								Find out more using our help and support site.{" "}
								<a href={DOCS_URL} target="_blank" rel="noreferrer">
									Click here to view
								</a>
								.
							</p>
							<p>
								View our quick start guide{" "}
								<a
									onClick={() => {
										dispatch({
											type: "UPDATE_MODAL",
											payload: {
												modalTitle: "Edun. Quick Start",
												modalContent: <OnboardingModal />,
											},
										});
									}}
								>
									here
								</a>
							</p>
						</ModalHelpContent>
					</ModalFooter>
				) : null}
			</ModalWrapper>
		</>
	);
};

export default HelpModal;
